import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
	{
		path: '/',
		component: () => import('@/views/index.vue'),
		name: 'HomeIndex',
	},
	{
		path: '/FirstLevelDirectory/about',
		component: () => import('@/views/component/FirstLevelDirectory.vue'),
		name: 'FirstLevelDirectoryIndex',
	},
	{
		path: '/SecondaryDirectory/about',
		component: () => import('@/views/component/SecondaryDirectory.vue'),
		name: 'SecondaryDirectoryIndex',
	},
	{
		path: '/LateMingBookStyle/about',
		component: () => import('@/views/component/LateMingBookStyle.vue'),
		name: 'LateMingBookStyleIndex',
	},
	{
		path: '/FamilyMotto/about',
		component: () => import('@/views/component/FamilyMotto.vue'),
		name: 'FamilyMottoIndex',
	},
	{
		path: '/Chronology/about',
		component: () => import('@/views/component/Chronology.vue'),
		name: 'ChronologyIndex',
	},
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(err => err)
}

export default new Router({
	mode: 'hash', // 去掉url中的# history
	routes: constantRoutes
})