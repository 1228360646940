import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
// 引入路由器
import 'vant/lib/index.css';
import '@vant/touch-emulator';
import router from "./router"
import store from './store'
// import "./js/openseadragon.min.js"
// import "./js/pano"
import "./common/reset.css"
import { Popup, Empty, Swipe, SwipeItem, ImagePreview } from 'vant';
Vue.use(Popup);
Vue.use(Empty);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VueRouter)
Vue.config.productionTip = false
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper);
// 全局注册
Vue.use(ImagePreview);
Vue.directive('swipe', {
  bind: function (el, binding) {
    let startX, startY, endX, endY;
    el.addEventListener('touchstart', function (event) {
      startX = event.touches[0].clientX;
      startY = event.touches[0].clientY;
    });
    el.addEventListener('touchend', function (event) {
      endX = event.changedTouches[0].clientX;
      endY = event.changedTouches[0].clientY;
      const diffX = endX - startX;
      const diffY = endY - startY;
      const absDiffX = Math.abs(diffX);
      const absDiffY = Math.abs(diffY);
      if (absDiffX > absDiffY) {
        // 横向滑动
        if (diffX > 0) {
          // 右滑
          binding.value('right', absDiffX);
        } else {
          // 左滑
          binding.value('left', absDiffX);
        }
      } else {
        // 纵向滑动
        if (diffY > 0) {
          // 下滑
          binding.value('down', absDiffY);
        } else {
          // 上滑
          binding.value('up', absDiffY);
        }
      }
    })
  }
})

// 自定义指令
Vue.directive('prevent-dblclick', {
  bind(el) {
    el.addEventListener('dblclick', event => {
      event.preventDefault()
    })
  }
}) // 忽略这个图标的数据
// Vue.config.productionTip = false

new Vue({
  // productionTip: false,
  render: h => h(App),
  store,
  router,
}).$mount('#app')

