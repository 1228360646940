<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',

}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
